import React, { useState, useContext, useEffect } from "react";
import styles from "./StudentRegistrations.module.css";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  DatePicker,
  Select,
  Space,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AddNewRower, GetOldRowerDetails } from "../../APIManager";
import { GetAgeBracketInfo } from "../../utility";
import { ConfigContext } from "../../ConfigContext";

const { Option } = Select;
const { confirm } = Modal;
const AddNewStudent = ({ visible, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  let [config, setConfig] = useContext(ConfigContext);
  const [studentNationality, SetStudentNationality] = useState("Pakistani");
  const [isKBCMember, SetIsKBCMember] = useState("Not Member");
  const [isCox, SetIsCox] = useState(false);
  const [submitEnabled, SetSubmitEnabled] = useState(false);

  const [errorMessage, SetErrorMessage] = useState();
  const [showErrorMessage, SetShowErrorMessage] = useState(false);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const {
    U25YOBLowerLimit,
    U21YOBLowerLimit,
    U18YOBLowerLimit,
    U15YOBLowerLimit,
  } = GetAgeBracketInfo(config.cpConfig);

  const constFields = [
    "StudentFullName",
    "StudentYOB",
    "StudentGender",
    "StudentEmailAddress",
    //"StudentPicture",
    "StudentNationality",
    //"StudentCNIC",
    "StudentCNICNumber",
    //"StudentDeclaration",
    "KBCMember",
    "PreviousRegatta",
  ];
  const [requiredFields, SetRequiredFields] = useState([
    "StudentFullName",
    "StudentYOB",
    "StudentGender",
    //"StudentPicture",
    //"StudentCNIC",
    "StudentCNICNumber",
    "StudentEmailAddress",
    "StudentNationality",
    //"StudentDeclaration",
    "KBCMember",
    "PreviousRegatta",
  ]);

  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
    form.setFieldsValue({
      StudentNationality: "Pakistani",
    });
  }, [visible]);

  const onFinish = (values) => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm you want to add this student",
      okText: "Submit New Student",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        SetWaitingOnResponse(true);
        AddNewRower(form.getFieldsValue())
          .then((res) => {
            onSubmit();
            SetWaitingOnResponse(false);
          })
          .catch(() => {
            SetShowErrorMessage(true);
            message.warning(
              "A Server/Network Error Occured, please check your connection"
            );
            SetWaitingOnResponse(false);
          });
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    var fields = constFields;
    if (isCox) {
      fields.push("CoxSeminar", "CoxTest", "CoxPreviousRegatta");
    } else {
      form.resetFields(["CoxSeminar"]);
      form.resetFields(["CoxTest"]);
      form.resetFields(["CoxPreviousRegatta"]);
    }
    if (isKBCMember === "Rowing Member" || isKBCMember === "Regular Member") {
      fields.push("StudentKBCNumber");
    } else {
      form.resetFields(["StudentKBCNumber"]);
    }
    SetRequiredFields(fields);
  }, [isCox, isKBCMember]);

  const handleFormValuesChange = (changedValues) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "KBCMember") {
      const value = changedValues[fieldName];
      SetIsKBCMember(value);
    }
    if (fieldName === "COX") {
      const value = changedValues[fieldName];
      SetIsCox(value);
    }
  };

  function onModalCancel() {
    confirm({
      title: "Unsaved Progress",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to cancel. Data entered will be lost",
      okText: "Cancel New Student",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        onCancel();
      },
      onCancel() {},
    });
  }

  async function FetchOldRowerDetails(e) {
    const cnic = e.target.value;
    GetOldRowerDetails(cnic).then(async (res) => {
      const details = res.data;
      if (details.length > 0) {
        var rower = [...details].sort((a, b) => b.Year.localeCompare(a.Year))[0]
          .Rower;
        console.log(rower);
        form.setFieldsValue({
          StudentFullName: rower.Name,
          StudentYOB: rower.YOB,
          StudentGender: rower.Gender,
          StudentPhoneNumber: rower.PhoneNumber,
          KBCMember: rower.KBCMember,
          StudentKBCNumber: ["Regular Member", "Rowing Member"].includes(
            rower.KBCMember
          )
            ? rower.KBCNumber
            : null,

          PreviousRegatta: true,
        });
        SetIsKBCMember(rower.KBCMember);

        try {
          await form.validateFields();
          //  message.success("Validation successful!");
        } catch (err) {
          //message.error("Validation failed!");
        }

        message.success("Fetched rower details from previous ISR");
      }
    });
  }

  return (
    <Modal
      title="Add New Student"
      forceRender
      visible={visible}
      onOk={onSubmit}
      onCancel={onModalCancel}
      okText="Submit"
      cancelText="cancel"
      width="800px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <Form
        form={form}
        layout={"vertical"}
        size="large"
        onValuesChange={handleFormValuesChange}
        onChange={(form) => {}}
        autoComplete={false}
        onFinish={onFinish}
      >
        <Form.Item
          label="Nationality"
          name="StudentNationality"
          rules={[{ required: true, message: "Please input Nationality" }]}
        >
          <NationalitySelect SetStudentNationality={SetStudentNationality} />
        </Form.Item>
        <CNICInput
          nationality={studentNationality}
          onPressEnter={FetchOldRowerDetails}
        />
        Press enter to fetch details from ISR database
        <Form.Item
          label="Student Full Name (must be identical to submitted ID document)"
          name="StudentFullName"
          rules={[
            { required: true, message: "Please input Student Full Name" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Student Year of Birth"
          name="StudentYOB"
          shouldUpdate
          rules={[
            { required: true, message: "Please input Student Year of Birth" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !(value < U25YOBLowerLimit || value > 2021)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    `Please enter a valid DOB between ${U25YOBLowerLimit} and 2021`
                  )
                );
              },
            }),
          ]}
        >
          <CategorySelect />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="StudentGender"
          rules={[{ required: true, message: "Please input Gender" }]}
        >
          <Select>
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Email Address"
          name="StudentEmailAddress"
          rules={[
            {
              required: true,
              message: "Please input student's Email Address",
            },
            { type: "email", message: "Please enter a valid Email Address" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Student Phone Number"
          name="StudentPhoneNumber"
          rules={
            [
              /*  {
              pattern: new RegExp(/^03[0-9]{2}[0-9]{7}$/),
              message: "Please enter a valid number in the format: 03001234567",
            }, */
            ]
          }
        >
          <Input />
        </Form.Item>
        <div>
          Student Photograph Requirements
          <ol>
            <li>
              Photo must be a soft copy of a photograph provided by a
              professional photographer. Snapshots/scans of hard copy
              photographs will not be accepted
            </li>
            <li>Photo must meet NADRA requirements</li>
            <li>Photo must not be older than 6 months</li>
            <li>Format must be JPEG/JPG/PNG</li>
            <li>
              Once uploaded, please adjust photo so that the candidate’s head
              fills the marked space
            </li>
          </ol>
        </div>
        <Form.Item
          name="StudentPicture"
          label="Student Photograph"
          /*  rules={[
            {
              required: true,
              message: "Please upload Student Picture",
            },
          ]} */
          className="CustomImageUpload"
        >
          <PhotoFileUpload />
        </Form.Item>
        <Form.Item
          label="Upload Scanned CNIC/Smart Card/Passport"
          name="StudentCNIC"
          /*  rules={[
            {
              required: true,
              message: "Please upload Scanned CNIC/Smart Card/Passport",
            },
          ]} */
        >
          <FileUpload />
        </Form.Item>
        <Form.Item
          label="Upload Scanned Student Declaration"
          name="StudentDeclaration"
          /*  rules={[
            {
              required: true,
              message: "Please upload Scanned Student Declaration",
            },
          ]} */
        >
          <FileUpload />
        </Form.Item>
        <Form.Item
          label={"KBC Membership Status"}
          name={"KBCMember"}
          rules={[
            {
              required: true,
              message: "Please seelect either 'Yes' or 'No'",
            },
          ]}
        >
          <Select>
            <Option value="Not Member">Not Member</Option>
            <Option value="Application in Process">
              Application in Process
            </Option>
            <Option value="Module">Module</Option>
            <Option value="Rowing Member">Rowing Member</Option>
            <Option value="Regular Member">Regular Member</Option>
          </Select>
        </Form.Item>
        {isKBCMember === "Regular Member" || isKBCMember === "Rowing Member" ? (
          <Form.Item
            label="Student KBC Membership Number"
            name="StudentKBCNumber"
            dependencies={["KBCMember"]}
            rules={[
              {
                required: isKBCMember,
                message: "Please input your Student KBC Membership Number",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  var rowingregex1 = /^[9][0-9]{4}$/;
                  var rowingregex2 = /^[9][9][0-9]{4}$/;
                  var regularregex1 = /^[0-9]{4}$/;
                  if (isKBCMember === "Rowing Member") {
                    if (
                      !value ||
                      rowingregex1.test(value) ||
                      rowingregex2.test(value)
                    ) {
                      return Promise.resolve();
                    }
                  } else {
                    if (!value || regularregex1.test(value)) {
                      return Promise.resolve();
                    }
                  }
                  return Promise.reject(
                    new Error(`Please enter a valid Membership Number`)
                  );
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        ) : null}
        <Form.Item
          label={"Has student taken part in any previous KBC Regatta?"}
          name={"PreviousRegatta"}
          rules={[
            {
              required: true,
              message: "Please select either 'Yes' or 'No'",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            return (
              <Space
                style={{ justifyContent: "flex-end", width: "100%" }}
                width="100%"
                align="end"
              >
                <Button type="default" onClick={onModalCancel}>
                  Cancel
                </Button>
                <Button
                  size={"large"}
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !requiredFieldsTouched(form, requiredFields) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length ||
                    waitingOnResponse
                  }
                >
                  Submit
                </Button>
              </Space>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewStudent;
const CNICInput = ({ nationality, onPressEnter }) => {
  var isPaki = nationality === "Pakistani";
  return (
    <Form.Item
      label={`Student ${isPaki ? "Citzenship Number" : "Passport Number"}`}
      name="StudentCNICNumber"
      rules={[
        {
          required: true,
          message: `Please enter Student ${
            isPaki ? "Citzenship Number" : "Passport Number"
          }`,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            var cnicRegex = new RegExp(/^((\d{5}-\d{7})|(\d{6}-\d{6}))-[0-9]$/);
            if (!value || !isPaki || cnicRegex.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(
                `Please enter a valid CNIC in the format 42301-1234567-8`
              )
            );
          },
        }),
      ]}
    >
      <Input onPressEnter={onPressEnter} />
    </Form.Item>
  );
};
function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      returnValue = false;
    }
  });
  return returnValue;
}

const CategorySelect = ({ value, onChange }) => {
  const [config] = useContext(ConfigContext);
  const momentObj = moment(`${value}-01-01`, "YYYY-MM-DD");

  return (
    <div>
      <DatePicker
        allowClear={false}
        picker="year"
        onChange={(v) => {
          onChange(v?.year());
        }}
        value={momentObj}
      />
      {value ? (
        <div>Student Category: {YOBCategory(value, config)}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

function YOBCategory(DOB, config) {
  const {
    U25YOBLowerLimit,
    U21YOBLowerLimit,
    U18YOBLowerLimit,
    U15YOBLowerLimit,
  } = GetAgeBracketInfo(config.cpConfig);

  if (DOB >= U15YOBLowerLimit) {
    return "Under 15";
  }
  if (DOB >= U18YOBLowerLimit) {
    return "Under 18";
  }
  if (DOB >= U21YOBLowerLimit) {
    return "Under 21";
  }
  if (DOB >= U25YOBLowerLimit) {
    return "Under 25";
  }
}

const NationalitySelect = ({ value, onChange, SetStudentNationality }) => {
  return (
    <>
      {" "}
      Please pick the option you will provide documents for for verification{" "}
      <Select
        value={value}
        onChange={(value) => {
          onChange(value);
          SetStudentNationality(value);
        }}
      >
        <Option value="Pakistani">Pakistani / NICOP</Option>
        <Option value="Foreign National">Foreign National</Option>
      </Select>
    </>
  );
};

const PhotoFileUpload = ({ value, onChange, onUpload }) => {
  return (
    <ImgCrop rotate aspect={35 / 45}>
      <Upload
        maxCount={1}
        accept=".png,.jpg,.jpeg"
        listType="picture-card"
        onRemove={() => {
          onChange("");
        }}
        onChange={() => {}}
        beforeUpload={(file) => {
          let reader = new FileReader();
          reader.addEventListener("load", function (e) {
            let text = e.target.result;
            onChange(text);
          });
          reader.readAsDataURL(file);
          //Dont Prevent upload to avoid thumbnail bug hence returning true
          return true;
        }}
      >
        <Button>Select File</Button>
      </Upload>
    </ImgCrop>
  );
};

const FileUpload = ({ value, onChange, onUpload }) => {
  return (
    <Upload
      maxCount={1}
      accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      onRemove={() => {
        onChange("");
      }}
      onChange={() => {}}
      beforeUpload={(file) => {
        let reader = new FileReader();
        reader.addEventListener("load", function (e) {
          let text = e.target.result;
          onChange(text);
        });
        reader.readAsDataURL(file);

        // Prevent upload
        return false;
      }}
    >
      <Button>Select File</Button>
    </Upload>
  );
};
