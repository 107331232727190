import React, { useState, useContext, useEffect } from "react";
import styles from "./TeamEventRegistrations.module.css";
import { GetRowers, AddNewRaceEntry, GetCoxes } from "../../APIManager";
import { Form, Button, Modal, Select, Space, Input } from "antd";
import ImgCrop from "antd-img-crop";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { RaceRowerFormats } from "../../constants";

const { Option, OptGroup } = Select;
const { confirm } = Modal;
const AddNewAssignment = ({
  visible,
  onSubmit,
  onCancel,
  race,
  raceEntries,
}) => {
  const [form] = Form.useForm();
  const [rowers, SetRowers] = useState([]);
  const [coxes, SetCoxes] = useState([]);
  const [validRowers, SetValidRowers] = useState([]);
  const [validCoxers, SetValidCoxers] = useState([]);

  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const {
    Rowers: RowerFields,
    Cox: CoxFields,
    SubRower: SubRowerFields,
    SubCox: SubCoxFields,
  } = GetFields(race);
  const AllFields = RowerFields.concat(CoxFields)
    .concat(SubRowerFields)
    .concat(SubCoxFields);

  useEffect(() => {
    GetRowers().then((res) => {
      SetRowers(res.data);
      ProcessValidRowers(res.data);
      //  ProcessValidCoxers(res.data);
    });
    GetCoxes().then((res) => {
      SetCoxes(res.data);
      ProcessValidCoxers(res.data);
    });
  }, [visible]);

  function ProcessValidRowers(rowers) {
    var validAgeGroups = GetValidAgeGroups();
    var validGender = GetValidGender();
    var valid = [];
    rowers.forEach((rower) => {
      //Add max race conditions here
      //Age Group Check
      if (!validAgeGroups.includes(rower.AgeGroup)) {
        return;
      }
      //Gender Check
      if (rower.Gender !== validGender) {
        return;
      }
      valid.push(rower);
    });
    valid.sort(function (a, b) {
      var textA = a.Name.toUpperCase();
      var textB = b.Name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    SetValidRowers(valid);
  }

  function RowerRaceLimit(id, role) {
    var Class;
    if (race.includes("00m")) {
      Class = "Indoor Race";
    } else {
      Class = "Water Race";
    }
    var rowingevents = 0;
    var coxevents = 0;
    var indoorevents = 0;
    raceEntries.forEach((entry) => {
      if (entry.Class === "Indoor Race") {
        if (entry.Rowers.filter((e) => e._id === id).length > 0) {
          indoorevents++;
        }
      }
      if (entry.Class === "Water Race") {
        if (entry.Rowers.filter((e) => e._id === id).length > 0) {
          rowingevents++;
        }
        if (entry.Coxers.filter((e) => e._id === id).length > 0) {
          coxevents++;
        }
      }
    });
    if (role === "rower") {
      if (Class == "Indoor Race") {
        return indoorevents < 2
          ? { valid: true }
          : {
              valid: false,
              message: "Can only take part in max two indoor events",
            };
      }
      if (Class === "Water Race") {
        if (coxevents === 0) {
          return rowingevents < 2
            ? { valid: true }
            : {
                valid: false,
                message: "Can only take part in max two rowing events",
              };
        } else if (coxevents === 1) {
          return rowingevents < 2
            ? { valid: true }
            : {
                valid: false,
                message: "Can only take part in max two rowing events",
              };
        } else if (coxevents === 2) {
          return rowingevents < 1
            ? { valid: true }
            : {
                valid: false,
                message:
                  "Can only take part in max one rowing event if coxing for 2 events",
              };
        } else if (coxevents === 3) {
          return {
            valid: false,
            message:
              "Cant take part in any rowing event if coxing for 3 events",
          };
        }
      }
    }
    if (role === "cox") {
      if (rowingevents > 1) {
        return coxevents < 1
          ? { valid: true }
          : {
              valid: false,
              message:
                "Can only take part in max one cox event if taking part in 2 rowing events",
            };
      } else {
        return coxevents < 3
          ? { valid: true }
          : {
              valid: false,
              message:
                "Can only take part in max three cox events if not rowing",
            };
      }
    }
    console.log(rowingevents);
    return false;
  }
  function GetValidAgeGroups() {
    var validAgeGroups = [];
    if (race.includes("Under 25")) {
      validAgeGroups.push("Under 25");
      validAgeGroups.push("Under 21");
    }
    if (race.includes("Under 21")) {
      validAgeGroups.push("Under 21");
      validAgeGroups.push("Under 18");
      validAgeGroups.push("Under 15");
    } else if (race.includes("Under 18")) {
      validAgeGroups.push("Under 18");
      validAgeGroups.push("Under 15");
    } else if (race.includes("Under 15")) {
      validAgeGroups.push("Under 15");
    }
    return validAgeGroups;
  }

  function GetValidGender() {
    if (race.includes("Boys")) {
      return "Male";
    } else {
      return "Female";
    }
  }

  function ProcessValidCoxers(rowers) {
    var validAgeGroups = GetValidAgeGroups();
    var valid = [];
    rowers.forEach((rower) => {
      //Add max race conditions here
      //Age Group Check
      if (!validAgeGroups.includes(rower.AgeGroup)) {
        return;
      }
      /*  if (!rower.COX) {
        return;
      } */
      valid.push(rower);
    });
    valid.sort(function (a, b) {
      var textA = a.Name.toUpperCase();
      var textB = b.Name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    SetValidCoxers(valid);
  }

  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const onFinish = (values) => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm you want to add this entry",
      okText: "Submit Entry",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        SubmitForm();
      },
      onCancel() {},
    });
  };

  function onModalCancel() {
    confirm({
      title: "Unsaved Progress",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to cancel. Data entered will be lost",
      okText: "Cancel New  Assignment",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        onCancel();
      },
      onCancel() {},
    });
  }

  function SubmitForm() {
    const formValues = form.getFieldsValue();
    var rowers = [];
    var coxers = [];
    var subrowers = [];
    var subcoxers = [];
    RowerFields.forEach((field) => {
      if (formValues[field]) {
        rowers.push(formValues[field]);
      }
    });
    CoxFields.forEach((field) => {
      if (formValues[field]) {
        coxers.push(formValues[field]);
      }
    });
    SubRowerFields.forEach((field) => {
      if (formValues[field]) {
        subrowers.push(formValues[field]);
      }
    });
    SubCoxFields.forEach((field) => {
      if (formValues[field]) {
        subcoxers.push(formValues[field]);
      }
    });

    var AgeGroup;
    if (race.includes("Under 25")) {
      AgeGroup = "Under 25";
    } else if (race.includes("Under 21")) {
      AgeGroup = "Under 21";
    } else if (race.includes("Under 18")) {
      AgeGroup = "Under 18";
    } else if (race.includes("Under 15")) {
      AgeGroup = "Under 15";
    }

    var Gender = GetValidGender();

    var Class;
    if (race.includes("00m")) {
      Class = "Indoor Race";
    } else {
      Class = "Water Race";
    }

    console.log(subcoxers);
    SetWaitingOnResponse(true);
    AddNewRaceEntry(
      race,
      AgeGroup,
      Gender,
      Class,
      rowers,
      coxers,
      subrowers,
      subcoxers
    )
      .then(() => {
        SetWaitingOnResponse(false);
        onSubmit();
      })
      .catch(() => {
        SetWaitingOnResponse(false);
        Modal.info({
          title: "A Server / Network error has occured",
          content: (
            <div>
              Unable to add race entry. Please check you internet, or contact
              KBC
            </div>
          ),
          onOk() {},
        });
      });
  }

  return (
    <Modal
      title={race}
      forceRender
      visible={visible}
      onCancel={onModalCancel}
      okText="Submit"
      cancelText="cancel"
      width="800px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <Form
        form={form}
        size="large"
        onFieldsChange={() => {
          // console.log(form.getFieldsValue());
        }}
        autoComplete={false}
        onFinish={onFinish}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 10 }}
      >
        {RowerFields?.map(function (item, i) {
          return (
            <Form.Item
              label={item}
              name={item}
              dependencies={AllFields}
              rules={[
                {
                  required: true,
                  message: "Please select a rower'",
                },
                ({ getFieldsValue, getFieldValue }) => ({
                  validator(_, value) {
                    // console.log(getFieldsValue());
                    var isNameRepeated = IsNameRepeated(value, getFieldsValue);
                    if (!value || !isNameRepeated) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(`Cannot repeat same person`)
                    );
                  },
                }),
                ({ getFieldsValue, getFieldValue }) => ({
                  validator(_, value) {
                    // console.log(getFieldsValue());
                    var limit = RowerRaceLimit(value, "rower");
                    if (!value || limit.valid) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(limit.message));
                  },
                }),
              ]}
            >
              <Select
                showSearch
                placeholder="Select a rower"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                <OptGroup label="Under 25">
                  {validRowers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 25";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 21">
                  {validRowers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 21";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 18">
                  {validRowers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 18";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 15">
                  {validRowers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 15";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
              </Select>
            </Form.Item>
          );
        })}
        {CoxFields?.map(function (item, i) {
          return (
            <Form.Item
              label={item}
              name={item}
              dependencies={AllFields}
              rules={[
                {
                  required: true,
                  message: "Please select a COX",
                },
                ({ getFieldsValue, getFieldValue }) => ({
                  validator(_, value) {
                    // console.log(getFieldsValue());
                    var isNameRepeated = IsNameRepeated(value, getFieldsValue);
                    if (!value || !isNameRepeated) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(`Cannot repeat same person`)
                    );
                  },
                }),
                ({ getFieldsValue, getFieldValue }) => ({
                  validator(_, value) {
                    // console.log(getFieldsValue());
                    var limit = RowerRaceLimit(value, "cox");
                    if (!value || limit.valid) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(limit.message));
                  },
                }),
              ]}
            >
              <Select
                showSearch
                placeholder="Select a COX"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                <OptGroup label="Under 21">
                  {validCoxers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 21";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 18">
                  {validCoxers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 18";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 15">
                  {validCoxers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 15";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
              </Select>
            </Form.Item>
          );
        })}
        {SubRowerFields?.map(function (item, i) {
          return (
            <Form.Item
              label={item}
              name={item}
              dependencies={AllFields}
              rules={[
                {
                  required: false,
                  message: "Please select a sub rower'",
                },
                ({ getFieldsValue, getFieldValue }) => ({
                  validator(_, value) {
                    // console.log(getFieldsValue());
                    var isNameRepeated = IsNameRepeated(value, getFieldsValue);
                    if (!value || !isNameRepeated) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(`Cannot repeat same person`)
                    );
                  },
                }),
              ]}
            >
              <Select
                showSearch
                placeholder="Select a sub rower"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                <OptGroup label="Under 25">
                  {validRowers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 25";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 21">
                  {validRowers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 21";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 18">
                  {validRowers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 18";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 15">
                  {validRowers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 15";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
              </Select>
            </Form.Item>
          );
        })}
        {SubCoxFields?.map(function (item, i) {
          return (
            <Form.Item
              label={item}
              name={item}
              dependencies={AllFields}
              rules={[
                {
                  required: false,
                  message: "Please select a sub cox",
                },
                ({ getFieldsValue, getFieldValue }) => ({
                  validator(_, value) {
                    // console.log(getFieldsValue());
                    var isNameRepeated = IsNameRepeated(value, getFieldsValue);
                    if (!value || !isNameRepeated) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(`Cannot repeat same person`)
                    );
                  },
                }),
              ]}
            >
              <Select
                showSearch
                placeholder="Select a sub cox"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                <OptGroup label="Under 21">
                  {validCoxers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 21";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 18">
                  {validCoxers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 18";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
                <OptGroup label="Under 15">
                  {validCoxers
                    ?.filter((r) => {
                      return r.AgeGroup === "Under 15";
                    })
                    .map(function (item, i) {
                      return <Option value={item._id}>{item.Name}</Option>;
                    })}
                </OptGroup>
              </Select>
            </Form.Item>
          );
        })}

        <Form.Item shouldUpdate={true}>
          {() => {
            return (
              <Space
                style={{ justifyContent: "flex-end", width: "100%" }}
                width="100%"
                align="end"
              >
                <Button type="default" onClick={onModalCancel}>
                  Cancel
                </Button>
                <Button
                  size={"large"}
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !requiredFieldsTouched() ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length ||
                    waitingOnResponse
                  }
                >
                  Submit
                </Button>
              </Space>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
  function IsNameRepeated(name, getFieldsValue) {
    var keys = Object.keys(getFieldsValue());
    var count = 0;
    keys.forEach((key) => {
      if (getFieldsValue()[key] === name) {
        count++;
      }
    });
    if (count > 1) {
      // console.log("repeated");
      return true;
    } else {
      //  console.log("NOT repeated");
      return false;
    }
  }
  function requiredFieldsTouched() {
    var returnValue = true;
    RowerFields.concat(CoxFields).forEach((element) => {
      if (!form.isFieldsTouched([element])) {
        returnValue = false;
      }
    });
    return returnValue;
  }
};

function GetFields(race) {
  var keys = Object.keys(RaceRowerFormats);
  var fields;
  keys.forEach((type) => {
    if (race.includes(type)) {
      fields = RaceRowerFormats[type];
      return;
    }
  });
  return fields ? fields : { Rowers: [], Cox: [], SubRower: [], SubCox: [] };
}

const NameField = ({ onChange, value, validRowers }) => {
  return (
    <Select
      showSearch
      placeholder="Select a rower"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={onChange}
    >
      {validRowers?.map(function (item, i) {
        return <Option value={item._id}>{item.Name}</Option>;
      })}
    </Select>
  );
};

function GetAutoCompleteOptions(rowers) {
  var options = [];
  rowers.forEach((rower) => {
    options.push({ label: rower.Name, value: rower.Name });
  });
  return options;
}

export default AddNewAssignment;
